import React, { useEffect, useState } from "react";
import { BsShare } from "react-icons/bs";
import { IoIosMore } from "react-icons/io";

import { FiRefreshCw, FiPlus, FiMinus } from "react-icons/fi";

import {
  extraordinaryApe,
  verify,
  whistleApe,
  blueprint,
} from "../../../images";
import { Button, Checkbox, Heading, Text } from "../../common";
import classes from "./HeroSection.module.css";
import clsx from "clsx";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { BrowserProvider, MaxInt256, parseEther } from "ethers";
import { Contract } from "ethers";
import { useWeb3Modal } from "@web3modal/ethers/react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const HeroSection = () => {
  const [agreeToTermsAndCondition, setAgereeToTermsAndCondition] =
    useState(localStorage.getItem("agreeToTerms") == "true");
  const { open } = useWeb3Modal();

  const { referrer_address } = useParams();

  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const [amount, setAmount] = React.useState(1);
  const [price, setPrice] = React.useState(3333.33);

  const [usdtAllowance, setUsdtAllowance] = React.useState(0);
  const [unsAlowance, setUnsAllowance] = React.useState(0);
  const details = [
    { key: "Number of rooms", value: `45` },
    { key: "Delux room size", value: "345 Sq Ft " },
    { key: "Junior Suite", value: "700 Sq Ft " },
  ];

  let presale_address = "0x59814B10aC0282Ea00469a945142d3F183DEFc42";

  const erc20Abi = [
    "function name() view returns (string)",
    "function symbol() view returns (string)",
    "function decimals() view returns (uint8)",
    "function totalSupply() view returns (uint)",
    "function allowance(address owner, address spender) view returns (uint)",
    "function balanceOf(address) view returns (uint)",
    "function transfer(address to, uint amount)",
    "function approve(address spender, uint amount)",
    "event Transfer(address indexed from, address indexed to, uint amount)",
  ];

  const saleAbi = [
    {
      type: "constructor",
      inputs: [
        { name: "_usdt_token", type: "address", internalType: "address" },
        { name: "_uns_token", type: "address", internalType: "address" },
        { name: "_vault", type: "address", internalType: "address" },
        { name: "_sale", type: "address", internalType: "address" },
        { name: "_usdt_rate", type: "uint256", internalType: "uint256" },
      ],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "isDiscounted",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "item",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract Item" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "owner",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "propertyPurchase",
      inputs: [
        { name: "amount", type: "uint256", internalType: "uint256" },
        { name: "receiver", type: "address", internalType: "address" },
        { name: "referrer", type: "address", internalType: "address" },
      ],
      outputs: [{ name: "success", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "saleContract",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "setDiscounted",
      inputs: [
        { name: "_user", type: "address", internalType: "address" },
        { name: "_isDiscounted", type: "bool", internalType: "bool" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "setItem",
      inputs: [{ name: "_item", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "totalPurchased",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "totalSold",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "uns_token",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract IERC20" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "updateUsdtRate",
      inputs: [
        { name: "_usdt_rate", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "usdt_rate",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "usdt_token",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract IERC20" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "vault",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "event",
      name: "ItemPurchased",
      inputs: [
        {
          name: "buyer",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "amount",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
      ],
      anonymous: false,
    },
  ];
  async function approveToken(tokenAddress, amount, allowance_set) {
    toast.loading("Approving token");
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(tokenAddress, erc20Abi, signer);
      const tx = await contract.approve(presale_address, amount);
      await tx.wait();
      toast.success("Token approved");
      allowance_set(amount);
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    } catch (e) {
      toast.error("Approval failed", { autoClose: 3000 });
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    }
  }

  async function checkApproval(tokenAddress) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(tokenAddress, erc20Abi, signer);
    const allowance = await contract.allowance(address, presale_address);
    return allowance;
  }

  async function purchase() {
    toast.loading("Purchasing property")

    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(presale_address, saleAbi, signer);
      const tx = await contract.propertyPurchase(
        amount,
        address,
        referrer_address
          ? referrer_address
          : "0xC0326c5D486F083a52286d97d8dF901a6C311015"
      );
      await tx.wait();
      toast.success("Purchase successful");
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    } catch (e) {
      toast.error("Purchase failed (Make sure you have enough balance in your wallet)", { autoClose: 3000 });
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
      // let parsed_error =JSON.parse(JSON.stringify(e));
      // alert(parsed_error.info.error.data.message);
    }
  }

  async function runner() {
    let allowance = await checkApproval(
      "0x55d398326f99059fF775485246999027B3197955"
    );
    setUsdtAllowance(allowance);

    let uns_allowance = await checkApproval(
      "0xEcf99aB23C11ddc6520252105308C251001AEfBB"
    );
    setUnsAllowance(uns_allowance);
  }
  useEffect(() => {
    runner();
  }, [isConnected]);


  const [agreeToTerms, setAgreeToTerms] = React.useState(localStorage.getItem("agreeToTerms") == "true");
  const [isViewedTerms, setIsViewedTerms] = React.useState(localStorage.getItem("isViewedTerms") == "true");
  function purchasebutton() {

    if (!agreeToTermsAndCondition) {
      return {
        title: "Connect Wallet",
        onClick: () => {
          // setAgereeToTermsAndCondition(true);
        },
      };
    }

    // if (!isViewedTerms) {
    //   return {
    //     title: "View Terms and Conditions",
    //     onClick: () => {
    //       localStorage.setItem("isViewedTerms", true);

    //       setIsViewedTerms(true);
    //     },
    //   };
    // }

    // if (!agreeToTerms) {
    //   return {
    //     title: "Agree to Terms and Conditions",
    //     onClick: () => {
    //       localStorage.setItem("agreeToTerms", true);
    //       setAgreeToTerms(true);
    //     },
    //   };
    // }

    if (!isConnected) {
      return {
        title: "Connect Wallet",
        onClick: () => {
          open();
        },
      };
    }
    if (usdtAllowance < parseEther((amount * price).toString())) {
      return {
        title: "Approve USDT",
        onClick: () => {
          approveToken("0x55d398326f99059fF775485246999027B3197955", MaxInt256, setUsdtAllowance);
        },
      };
    }

    if (unsAlowance < parseEther((amount * price).toString())) {
      return {
        title: "Approve UNS",
        onClick: () => {
          approveToken("0xEcf99aB23C11ddc6520252105308C251001AEfBB", MaxInt256, setUnsAllowance);
        },
      };
    } else {
      return {
        title: "Purchase",
        onClick: () => {
          purchase();
        },
      };
    }
  }

  const [isCopied, setIsCopied] = React.useState(false);

  return (
    <section className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <img
          height={400}
          src={"https://i.ibb.co/vzfXHJB/Untitled-design-1.png"}
          alt="#"
          className={classes.img}
        />

        <div className={classes.infoContainer}>
          <div className={classes.verificationContainer}>
            <img src={verify} alt="#" className={classes.verifyIcon} />
            <Text xs secondary>
              396, 191 Kasetsin 2 Alley, Pattaya City, Bang Lamung District,
              Chon Buri 20150, Thailand
            </Text>
          </div>{" "}
          <Heading medium primary semiBold className={classes.name}>
            The Hideaway Resort
          </Heading>
          <div onClick={() => {
            let link = "https://youtu.be/zzE3fpOqJbc?si=IKT0MLJcgRXSTyE4";
            window.open(link, "_blank");
          }} className={classes.ownedBy}>
            <Text base secondary>
              Governed by
            </Text>{" "}
            <Text

              onClick={() => {
                let link = "https://youtu.be/zzE3fpOqJbc?si=IKT0MLJcgRXSTyE4";
                window.open(link, "_blank");
              }}

              base primary className={classes.owner}>
              DAO
            </Text>
          </div>
          <div className={classes.buttonContainer}>
            {/* if you want you can pass onClick instead of link */}
            <Button
              onClick={() => {
                if (!isConnected) {
                  open();
                }
                let ref = "https://properties.uns.technology/hideaway/";
                let wallet = address;
                let referral_link = ref + wallet;

                //  alert(referral_link)

                navigator.clipboard.writeText(referral_link);

                setIsCopied(true);

                setTimeout(() => {
                  setIsCopied(false);
                }, 2000);
              }}
              surfaceSoft
            >
              <BsShare />{" "}
              {isCopied ? "Copied" : address ? "Share" : "Connect Wallet"}
            </Button>
            <Button
              link="https://maps.app.goo.gl/VE15vTexPB4srDAs5"
              surfaceSoft
            >
              <img
                width="12"
                src="https://cdn-icons-png.flaticon.com/128/2875/2875433.png"
                alt="#"
              />
              Google Map
            </Button>
            <Button
              onClick={() => {
                setAmount(1);
              }}
              surfaceSoft
            >
              <FiRefreshCw /> Reset Amount
            </Button>

            {/* <Button onClick={() => {}} surfaceSoft className={classes.more}>
              <IoIosMore className={classes.moreIcon} />
            </Button> */}
          </div>
          <div className={classes.priceAndBuyContainer}>
            <div className={classes.priceContainer}>
              <Heading base secondary>
                Price
              </Heading>
              <div className={classes.price}>
                <Heading medium primary className={classes.ethPrice}>
                  {(amount * price).toFixed(2)}{" "}
                  <Text base secondary className={classes.currency}>
                    USDT
                  </Text>
                </Heading>
                <Text base secondary className={classes.usdPrice}>
                  {amount} NFT{amount > 1 ? "s" : ""}
                </Text>
              </div>
            </div>
            <Checkbox
              className={classes.termsCheck}
              checked={agreeToTermsAndCondition}
              setChecked={setAgereeToTermsAndCondition}
              label={
                <>
                  I agree to all{" "}
                  <a
                    // target="_blank"
                    // rel="noreferrer"
                    // href="/Terms_and_Conditions.pdf"

                    onClick={() => {
                      let link = "/Terms_and_Conditions.pdf";
                      window.open(link);
                    }}
                    className={classes.link}
                  >
                    Terms & Conditions
                  </a>
                </>
              }
            />
            <div className={classes.buyContainer}>
              <Button
                onClick={() => {
                  purchasebutton().onClick();
                }}
                wFull
              >
                {purchasebutton().title}
              </Button>{" "}
              <Button
                onClick={() => {
                  if (amount > 1) {
                    setAmount(amount - 1);
                  }
                }}
              >
                <FiMinus className={classes.plusIcon} />
              </Button>
              <Button
                onClick={() => {
                  setAmount(amount + 1);
                }}
              >
                <FiPlus className={classes.plusIcon} />
              </Button>
            </div>{" "}
            {/* <Button secondary onClick={() => {}} wFull>
              Place a bid
            </Button>{" "} */}
          </div>
        </div>
      </div>
      <div className={clsx("container", classes.container)}>
        <div className={classes.descriptionContainer}>
          <div className={classes.descriptionBox}>
            <Heading base primary>
              Description
            </Heading>
            <Text base secondary>
              The Hideaway Resort is a fully furnished 3-Star Deluxe Hotel in
              the heart of Pratyumnak, Pattaya, Thailand. The property houses 45
              rooms, 3 Junior Suits (700 sq ft) and 41 Deluxe (345 sq. ft) with
              facilities including TV, Aircon, Electric Water Heater, Wifi,
              Electric Kettle, Mini Bar Refrigerator, Safety locker and an
              attached balcony.
            </Text>{" "}
          </div>
          <div className={classes.descriptionBox}>
            <Heading base primary>
              Property Details:
            </Heading>
            <Text base secondary>
              Swimming Pool <br></br>
              Restaurant<br></br>
              Club/Lounge Bar<br></br>
              Covered Terrace for Parties<br></br>
            </Text>
          </div>
          <div className={classes.descriptionBox}>
            <Heading base primary>
              Location:
            </Heading>
            <Text base secondary>
              200 mt from the Cozy Beach<br></br>2 km from walking Street
              <br></br>
              800 mt to Buddha Mountain<br></br>
              1.5 km to Pattaya View Point<br></br>
            </Text>
          </div>{" "}
          {/* <Text base secondary>
            All rooms are large equipped with big TV, refrigerator and warm
            shower. You can experience the night life of Pattaya at the Bars
            located in the vicity of the Resort. Nearby are several of
            Convenient stores 7-11 and Family Mart and Authentic Thai Massage
            Centres.
          </Text> */}
          <div></div>
        </div>
        <img src={blueprint} alt="#" className={classes.blueprintImg} />
      </div>
      <div className={classes.elipes}></div>
    </section>
  );
};

export default HeroSection;
