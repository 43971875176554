import React, { useState } from "react";
import { RiShoppingBag4Line } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import Sidebar from "../../components/Home/Sidebar/Sidebar";
import clsx from "clsx";
import { Button, Heading, Text } from "../../components/common";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import classes from "./Home.module.css";
import { Layout1, Layout2, Layout3 } from "../../images/SvgComponent";
import { useTheme } from "../../components/ThemeContext/ThemeContext";
import Nfts from "../../components/Home/Nfts/Nfts";
import { nft1, nft2, nft3 } from "../../images";

const Home = () => {
  const { isDarkTheme } = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isTradingActive, setIsTradingActive] = useState(false);
  const tabs = ["Properties"];
  const [activeTab, setActiveTab] = useState("Nfts");

  const [activeLayout, setActiveLayout] = useState(1);
  const nfts = [
    {
      name: "The Hideway Resort",
      price: "$3333.33",
      img: "https://i.ibb.co/5kPbxtx/Hideaway.png",
      category: "Winter Madagascar",
      link_to: "/hideaway",
      description:
        "The Hideaway Resort is a fully furnished 3- Star Deluxe Hotel in the heart of Pratyumnak, Pattaya, Thailand. The property houses 45 rooms, 3 Junior Suits (700 sq ft) and 41 Deluxe (345 sq. ft) with facilities including TV, Aircon, Electric Water Heater, Wifi, Electric Kettle, Mini Bar Refrigerator, Safety locker and an attached balcony.",

      // "Property Details":
      propertyDetails:
        " Swimming Pool Restaurant Club/Lounge Bar Covered Terrace for Parties",

      location:
        "  200m from the Cozy Beach,  2km from The Walking Street, 800m to Buddha mountain, and 1.5 km to Pattaya view point.",
    }, //1
    {
      name: "Ultra Luxury Pool Villa",
      price: "",
      img: "https://i.ibb.co/pZsn0kX/Pool-Villa.png",
      category: "Winter Madagascar",
      location:
        "Very close to the upcoming prestigious METRO RAIL PROJECT in Pattaya, Thailand",
      description: `The Pool Villa is a 2500 Sq Mt, fully furnished, luxury home, owned by Cloud Nine Realty Company Limited which is  perfect for all vacation and housing needs. The Villa has 4 bedrooms, and 5 bathrooms, 1 study room, 1 Garden  Room, 1 servant and 1 driver room, along with a grand Living and Dining Area. The propery is located in NongPrue. and has a grand pool which not just gives the property an aesthetic charm but also act as the ultimate relaxation spot.`,
    }, //2
    {
      name: "Siam Heights Condominium (Pattaya)",
      price: "",
      img: "https://i.ibb.co/ZSTZsFW/PHOTO-2024-05-21-07-55-36.jpg",
      category: "Winter Madagascar",
      description: `his EIA Approved, North - East facing, 1556 Sq. Mt. Residential Apartment Project with 186 units of fully furnished, 1 BHK, ready-to-move residential apartments, located in the heart of Soi Chaiyapruyek, Pattaya, Thailand      `,
      propertyDetails: `Restaurant and Bar 
      Swimming Pool
      Gymnasium
      Jogging Track on Terrace
      24 Hr Security Service 
      Doctor on Call
      Car and tourism facilities along with tie up with Golf Clubs.`,
      location: ` 800 metres from the famous Jomtien Beach and in the heart of the city. The surrounding hosts high~end residential projects.`,
    }, //3
    {
      name: "Siam Golf View",
      price: "",
      img: "https://i.ibb.co/tcr0Phy/Siam-Golf-View.png",
      category: "Winter Madagascar",
      description: `This premium 10 acre village project by the name "Siam Golf View" is a 25 rai village project at Siam country club road Pattaya, Thailand, is ready with its total 125 Units, with plot sizes from 100 Sq Mts, 140 Sq Mts, 200 Sq Mts, 300 Sq Mtrs and 500 Sq Mts. It's a 125 villa project surrounded by 4 PGA golf courses and the Thai Polo ground. 
.      `,
      propertyDetails: ``,
      location: `In the Siam Country Club Road (within 20 kms from Pattaya city.`,
    }, //4
    {
      name: "3 Bedroom Condominium (Bangkok)",
      price: "",
      img: "https://i.ibb.co/s99vzG9/5.jpg",
      category: "Winter Madagascar",
      description: `Super Luxury Modern Condominium Fully Furnished with Ultra Modern Interiors and all White goods is the ultimate modern luxury experience at the heart of Bangkok on Thonglor Road, surrounded with numerous Shopping Centers, Restaurants, Schools, and Hospital, all within walkable distance. The apartment unit includes 3 Bedroom with 3 Bathroom, a seperate Living and Dining Area with Modern Kitchnette. A separate study room and 2 separate balconies`,
      propertyDetails: `Elevator, 
      Parking, 
      24-hours security,
      CCTV, 
      Swimming pool,
      Sauna,
      Gym, 
      Garden / BBQ, 
      Shop on premise, 
      Restaurant on premise, 
      Wi-Fi, 
      Meeting Room (VIP Room)`,
      location: `Asok, Sukhumvit, Bangkok, Thailand`,
    },
    {
      name: "Luxury Pool Villa",
      price: "",
      img: "https://i.ibb.co/4tn5LMP/Ultra-Luxury-6-bed.png",
      category: "Winter Madagascar",
      description: `This 6000 sq. ft. ultra-luxury, fully-furnished villa with rosewood furniture, property with 6 bedroom, 6 bathroom, , 2 Dining Area, 1 Dining Area with 2 car parking, is located inside the gated community at Sukhmit 89, Pattaya, Thailand. With State of the art Kitchen, a temperature-controlled swimming pool, and a fish pond, this exquisite villa offers a blend of luxury and serenity in the heart of Pattaya, Thailand.`,
      propertyDetails: `
      Swimming Pool,
Gymnasium
Jaccuzi
Study Room
Store Room
Pond
Elevated Lawn`,
      location: `The Villa is located inside the gated community at Sukhmit 89, Pattaya, Thailand.`,
    },
  ];
  const filteredNfts = nfts.filter((nft) =>
    nft.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  return (
    <div className={clsx("container", classes.container)}>
      <div className={classes.topHeader}>
        <div className={classes.info}>
          <Heading medium semiBold>
            REVOLUTIONIZING REAL ESTATE BY TOKENIZATION THROUGH NFT
          </Heading>
          <Text xs secondary>
            NFTs ensures secure, transparent, and expedited real estate
            transactions, minimizing traditional procedural hassles.
          </Text>
        </div>
      </div>

      <div className={classes.wrapper}>
        {/* <div onClick={() => setIsFilterActive((prev) => !prev)}>
           <Text xl primary>
            Filters
          </Text>
          <div className={classes.iconContainer}>
            {isFilterActive ? (
              <FaAngleUp className={classes.icon} />
            ) : (
              <FaAngleDown className={classes.icon} />
            )}
          </div> 
        </div> */}
        <div className={classes.tabs}>
          {tabs.map((tab, i) => (
            <p
              className={clsx(
                classes.tab,
                activeTab.toLowerCase() === tab.toLowerCase() &&
                classes.activeTab
              )}
              key={i}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </p>
          ))}
        </div>
        <div className={classes.inputContainer}>
          <input
            type="text"
            className={classes.input}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
          // placeholder="Type for collections, NFTs etc"
          />
          <Button
            onClick={() => { }}
            surfaceMedium
            className={classes.searchButton}
          >
            <FiSearch />
          </Button>
        </div>
        {/* <div
          className={classes.trading}
          onClick={() => setIsTradingActive((prev) => !prev)}
        >
          <Text xl primary>
            Trending
          </Text>
          <div className={classes.iconContainer}>
            {isTradingActive ? (
              <FaAngleUp className={classes.icon} />
            ) : (
              <FaAngleDown className={classes.icon} />
            )}
          </div>
        </div> */}
        {/* <Button onClick={() => {}} surfaceSoft className={classes.button}>
          <RiShoppingBag4Line className={classes.shop} />
        </Button>{" "}
        <Button onClick={() => {}} surfaceSoft className={classes.button}>
          <RiShoppingBag4Line className={classes.shop} />
        </Button>
        <div className={classes.layoutContainer}>
          <div
            className={clsx(
              classes.layout,
              activeLayout === 1 && classes.activeLayout
            )}
            onClick={() => setActiveLayout(1)}
          >
            <Layout1
              isDarkTheme
              color={
                activeLayout === 1
                  ? !isDarkTheme
                    ? "#2a2929"
                    : "#E8E8E8"
                  : "#747474"
              }
            />
          </div>{" "}
          <div
            className={clsx(
              classes.layout,
              activeLayout === 2 && classes.activeLayout
            )}
            onClick={() => setActiveLayout(2)}
          >
            <Layout2
              isDarkTheme
              color={
                activeLayout === 2
                  ? !isDarkTheme
                    ? "#2a2929"
                    : "#E8E8E8"
                  : "#747474"
              }
            />
          </div>{" "}
          <div
            className={clsx(
              classes.layout,
              activeLayout === 3 && classes.activeLayout
            )}
            onClick={() => setActiveLayout(3)}
          >
            <Layout3
              isDarkTheme
              color={
                activeLayout === 3
                  ? !isDarkTheme
                    ? "#2a2929"
                    : "#E8E8E8"
                  : "#747474"
              }
            />
          </div>
        </div> */}
      </div>
      <div className={classes.projectsWrapper}>
        <Sidebar />
        <Nfts nfts={filteredNfts} />
      </div>
    </div>
  );
};

export default Home;
